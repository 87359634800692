<template>
  <div class="products">
    <!-- <transition name="opaFadeIn">
    <loading v-if="isLoading" />
    </transition> -->
    <router-view />
    <back v-if="isChildPage" />
    <template v-if="!isChildPage">
    <header>
      <div class="backGroundYoutube">
        <youtube ref="topVideoYT" :video-id="topVideoId" :player-vars="topPlayerVars" width="100%" height="100%"
        @playing="isLoading=false" />
      </div>
      <img src="@/assets/image/products/FPlogo.svg">
      <top-title :subTitle="`Online &amp; Offline Interactive Game Marketing Solution \n FANSPLAY 玩粉絲 全方位遊戲互動行銷工具`"></top-title>
    </header>
    <div class="container">
      <page-main-body
        title=""
        subTitle=""
        paragraph1="在現今數位時代，快速地創建活動，提高行銷聲量，取得準確的潛在客戶名單，缺一不可。全方位遊戲行銷工具 FANSPLAY 一次到位，它涵蓋三大功能 - 10種遊戲模組、一頁網站，以及訊息機器人。絕對是數位行銷必備的好夥伴。"
        >
      </page-main-body>
      <h2 class="fontBigTitle">三大功能</h2>
      <h3 class="fontBigTitle">Main features</h3>
      <div class="marketingTools">
        <div class="tools game">
          <img :class="{'left': !hook1}" src="@/assets/image/products/gamePhone.png" >
          <marketing-tool-content :ID="1" :learnMore="{name: 'game'}" type="left" :hook="hook1" 
            title="遊戲模組" subTitle="Group &amp; Easy Game" detail="藉由遊戲創造互動樂趣聚集人流觸發集客力"
            :onlieAmount="11214" :attendAmount="456123" :visitAmount="935641">
          </marketing-tool-content>
        </div>
        <div class="tools onepage R">
          <img :class="{'right': !hook2}" src="@/assets/image/products/onepagePhone.png" >
          <marketing-tool-content :ID="2" :learnMore="{name: 'onePage'}" type="right" :hook="hook2"
            title="一頁網站" subTitle="One Page" detail="不管是簡單的線上報名頁面、專業的產品型錄，風格獨特的官網，都可以透過一頁網站創造屬於自己的 Landing Page"
            :onlieAmount="11214" :attendAmount="456123" :visitAmount="935641">
          </marketing-tool-content>
        </div>
        <!--
        <div class="tools chatbot">
          <img :class="{'left': !hook3}" src="@/assets/image/products/chatbotPhone.png" >
          <marketing-tool-content :ID="3" learnMore="comingSoon" type="left" :hook="hook3"
            title="訊息機器人" subTitle="Chatbot" detail="24 / 7 全年無休的客戶關係顧問提升銷售的最佳好夥伴"
            onlieAmount="-" attendAmount="-" visitAmount="-">
          </marketing-tool-content>
        </div>
        -->
      </div>
      <h2>讓您的模組曝光</h2>
      <h3>透過、Facebook、IG、LINE、直播、官網、立牌、包才包裝等社群媒介曝光模組</h3>
      <expose class="EXtrigger" :class="{'exposes': EXtrigger}"/>
      <h2 style="margin-top:100px;">曝光後... 如何抓住客戶</h2>
      <h3 class="CAtrigger" >利用模組的功能，誘導客戶、吸引客戶、留住客戶</h3>
      <catch-customer v-if="CAtrigger"/>
      <h2>完整漏斗解決方案</h2>
      <h3 class="FUtrigger">階段性的讓您了解行銷手法</h3>
      <funnel v-if="FUtrigger"/>
      <h2>廠商怎麼說?</h2>
      <div class="vendor video" :class="{'clicked': isPlay2}" @click="play(isPlay2, 'vendorVideoYT')">
        <div class="mask" v-if="!isPlay2">
          <i class="far fa-play-circle"></i>
          <img src="@/assets/image/products/preview2.png" >
        </div>
        <transition name="opaFadeIn">
          <loading id="youtubeLoading" v-if="play2Buffering " />
        </transition>
        <youtube ref="vendorVideoYT" :video-id="vendorVideoId" :player-vars="vendorPlayerVars" width="100%" height="100%"
          @playing="play2(true), play2Buffering = false" @paused="play2(false)" @buffering="play2Buffering = true" />
      </div>
    </div>
    </template>
    <a id="floatingBtn" href="https://www.tsaiyitech.com/activity/FansPlay_BoostYourPlan/tw/" target="_blank">
      <img src="../assets/image/products/FP-LOGO.svg" />
      <span class="fontSmaller">立即申請試用</span>
    </a>
    <footer-view />
  </div>
</template>

<script>
import loading from "@/components/loading.vue";
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import marketingToolContent from "@/components/marketingToolContent.vue";
import expose from "@/components/animations/expose.vue";
import catchCustomer from "@/components/animations/catchCustomer.vue";
import funnel from "@/components/animations/funnel.vue";
import back from "@/components/back.vue";
import footerView from "@/components/footerView.vue";

export default {
  name: "products",
  components: {
    loading,
    topTitle,
    pageMainBody,
    marketingToolContent,
    expose,
    catchCustomer,
    funnel,
    back,
    footerView
  },
  data() {
    return {
      isLoading: true,
      topVideoId: "50dQT5gAyks",
      topPlayerVars: {
        autoplay: 1,
        controls: 0,
        mute: 1,
        loop: 1,
        playsinline: 1,
        playlist: "50dQT5gAyks"
      },
      isPlay2: false,
      play2Buffering: false,
      vendorVideoId: "XJYzD_K7Zrk",
      vendorPlayerVars: {
        autoplay: 0,
        rel: 0,
        playsinline: 1
      },
      isChildPage: false,
      click: false,
      hook1: false,
      hook2: false,
      hook3: false,
      EXtrigger: false,
      CAtrigger: false,
      FUtrigger: false
    };
  },
  watch: {
    isChildPage() {
      $("html, body").animate({ scrollTop: 0 }, 0);
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.currentPage = "product";
    this.$refs.topVideoYT.player.playVideo();
    setTimeout(() => {
      this.$parent.isLoading = false;
    }, 1000);
  },
  methods: {
    play1(boolean) {
      boolean
      ? this.isPlay1 = true
      : this.isPlay1 = false
    },
    play2(boolean) {
      boolean
      ? this.isPlay2 = true
      : this.isPlay2 = false
    },
    play(boolean, ref) {
      boolean
      ? this.$refs[ref].player.pauseVideo()
      : this.$refs[ref].player.playVideo()
    },
    handleScroll() {
      if (!this.isChildPage) {
        var st = window.pageYOffset || document.documentElement.scrollTop;
        var hook = window.innerHeight;
        var vm = this;
        $(".tools").each(function(idx) {
          if (st > $(this).offset().top - hook * 0.5) {
            vm["hook" + (idx + 1)] = true;
          }
        });
        if (st > $(".EXtrigger").offset().top - hook * 0.8) {
          this.EXtrigger = true;
        }
        if (st > $(".CAtrigger").offset().top - hook * 0.8) {
          this.CAtrigger = true;
        }
        if (st > $(".FUtrigger").offset().top - hook * 0.8) {
          this.FUtrigger = true;
        }
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.EXtrigger {
  opacity: 0;
}

header {
  position: relative;
  background-image: none;
  width: 100vw;
  height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    transform: translateY(-50%);
    width: 30%;
    @media #{$tablet} {
      position: absolute;
      top: 35%;
    }
  }
  .backGroundYoutube {
    position: absolute;
    z-index: -99;
    transform: scale(1.5);
    width: 100%;
    height: 100%;
    filter: brightness(0.5);
    @media #{$phone} {
      transform: scale(2.7);
    }
  }
}
.products {
  color: #707070;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2,
  h3 {
    margin: 0;
  }
  h3 {
    font-weight: 300;
  }
  .video {
    position: relative;
    width: 80vw;
    height: 45vw;
    transition: 0.3s;
    margin: 50px 0;
    filter: brightness(0.5);
    overflow: hidden;
    .mask {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 100%;
      }
      .far {
        position: absolute;
        font-size: 100px;
        color: $mainPurple;
        top: calc(50% - 50px);
        left: calc(50% - 50px);
      }
    }
    #youtubeLoading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
    &.clicked {
      filter: brightness(1);
    }
    &.vendor {
      margin-top: 30px;
    }
  }
  .marketingTools {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      img {
        margin: 0 20px;
        width: 20vw;
        opacity: 1;
        transform: translateX(0);
        transition: 0.3s ease-out;
        @media #{$phone} {
          width: 80vw;
        }
      }
    }
    .tools {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 100vw;
      height: 46.25vw;
      @media #{$tablet} {
        margin: 10px 0;
      }
      @media #{$phone} {
        flex-direction: column !important;
        height: auto;
      }
      &.R {
        flex-direction: row-reverse;
      }
    }
  }
  #floatingBtn {
    position: fixed;
    background-color: #fff;
    right: 5%;
    bottom: 2%;
    width: 7%;
    min-width: 80px;
    padding: 20px 12px;
    z-index: 20;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-decoration: none;
    color: #707070;
    transition: .3s;
    &:hover {
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.16);
      transform: translateY(-2%);
    }
  }
}

.left {
  transform: translateX(-30px) !important;
  opacity: 0 !important;
}
.right {
  transform: translateX(30px) !important;
  opacity: 0 !important;
}
.fade {
  opacity: 0.3 !important;
}
</style>
