<template>
  <div class="back" @click="back" @mouseover.self="mouseover" @mouseout="mouseout">
    <i class="fas fa-arrow-left" @mouseover.stop="mouseover"></i>
  </div>
</template>

<script>
export default {
  name: "back",
  mounted() {
    this.randomColor();
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    randomColor() {
      var colors = ["#F12379", "#9D5DCE", "#79CCCF"];
        $(".back").css({
            "background-color": colors[Math.floor(Math.random() * 3)]
          })
    },
    handleResize() {
      this.randomColor();
    },
    mouseover() {
      var colors = ["#F12379", "#9D5DCE", "#79CCCF"];
      $(".back").css({
        "background-color": colors[Math.floor(Math.random() * 3)],
        "opacity": 1
      });
    },
    mouseout() {
      $(".back").css({ "opacity": 0});
    },
    handleScroll() {
      this.st = window.pageYOffset || document.documentElement.scrollTop;
      if (this.st > this.lastScrollTop) {
        $(".back").css({ "opacity": 0 });
      } else {
        $(".back").css({ "opacity": 1 });
      }
      this.lastScrollTop = this.st <= 0 ? 0 : this.st;
    }
  }
};
</script>


<style lang="scss">
@import "../assets/scss/style.scss";

.back {
  position: fixed;
  z-index: 10;
  left: 8%;
  bottom: 8%;
  color: #fff;
  font-size: 30px;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0;
  background-color: #999999;
  transition: 0.3s;
  min-width: 60px;
  min-height: 60px;
  @include center;
  @media #{$tablet} {
    opacity: 1;
  }
  @media #{$phone} {
    min-width: 50px;
    min-height: 50px;
    padding: 0;
    font-size: 20px;
    bottom: 4%;
  }
  &:hover {
    opacity: 1;
  }
}
</style>
