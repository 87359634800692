<template>
  <div class="marketingToolContent">
    <div class="static" v-if="type=='left'">
      <div class="titles">
        <div v-if="learnMore=='comingSoon'" class="comingSoonLabel">近期推出 Coming Soon</div>
        <font class="title">{{title}}</font> <br>
        <font class="subTitle">{{subTitle}}</font>
      </div>
      <hr>
      <div class="detail">{{detail}}</div>
      <router-link v-if="learnMore!='comingSoon'" :to="learnMore" class="learnMore">瞭解更多</router-link>
    </div>
    <div class="dynamic">
      <div class="onlieAmount">
        <span :id="`onlieAmount${ID}`" style="color:#f12379;">{{onlieAmount}}</span><br>
        活動上線數
      </div>
      <div class="attendAmount">
        <span :id="`attendAmount${ID}`" style="color:#9d4dce;">{{attendAmount}}</span><br>
        活動參與人數
      </div>
      <div class="visitAmount">
        <span :id="`visitAmount${ID}`" style="color:#73cccf;">{{visitAmount}}</span><br>
        造訪人數
      </div>
    </div>
    <div class="static" v-if="type=='right'">
      <div class="titles">
        <div v-if="learnMore=='comingSoon'" class="comingSoonLabel">近期推出 Coming Soon</div>
        <span class="title">{{title}}</span> <br>
        <span class="subTitle">{{subTitle}}</span>
      </div>
      <hr>
      <div class="detail">{{detail}}</div>
      <router-link v-if="learnMore!='comingSoon'" :to="learnMore" class="learnMore">瞭解更多</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ID: Number,
    title: String,
    subTitle: String,
    detail: String,
    learnMore: String | Object,
    onlieAmount: Number | String,
    attendAmount: Number | String,
    visitAmount: Number | String,
    type: String,
    hook: Boolean
  },
  data() {
    return {
      trigger: this.hook
    };
  },
  watch: {
    hook() {
      $("#onlieAmount" + this.ID).each(function() {
        if ($(this).text() == "-") {
          return;
        }
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text()
            },
            {
              duration: 3000,
              easing: "swing",
              step: function(now) {
                $(this).text(Math.ceil(now) + " +");
              }
            }
          );
      });
      $("#attendAmount" + this.ID).each(function() {
        if ($(this).text() == "-") {
          return;
        }
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text()
            },
            {
              duration: 3000,
              easing: "swing",
              step: function(now) {
                $(this).text(Math.ceil(now) + " +");
              }
            }
          );
      });
      $("#visitAmount" + this.ID).each(function() {
        if ($(this).text() == "-") {
          return;
        }
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text()
            },
            {
              duration: 3000,
              easing: "swing",
              step: function(now) {
                $(this).text(Math.ceil(now) + " +");
              }
            }
          );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

.marketingToolContent {
  display: flex;
  height: 60%;
  width: 50%;
  opacity: 1;
  transition: 0.3s;
  @media #{$tablet} {
    height: 80%;
  }
  @media #{$phone} {
    width: 80vw;
    height: 60vh;
  }
  .static {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @media #{$phone} {
      justify-content: center;
      div {
        margin: 10px 0;
      }
    }
    .titles {
      .comingSoonLabel {
        @include font(smaller);
        color: #fff;
        background-color: $mainBlue;
        padding: 3%;
      }
      .title {
        @include font(large);
      }
      .subTitle {
        @include font(small);
        font-weight: 700;
      }
    }
    hr {
      background-color: #707070;
      height: 1px;
      width: 50px;
    }
    .detail {
      @include font(small);
      font-weight: 300;
      @media #{$tablet} {
        @include font(smaller);
      }
      @media #{$phone} {
        @include font(small);
      }
    }
    a {
      @include font(medium);
      text-decoration: none;
      color: #fff;
      background-color: $mainRed;
      white-space: nowrap;
      font-weight: 700;
      width: 60%;
      padding: 10px;
      transition: 0.3s;
      &:hover {
        background-color: #b8004e;
      }
      @media #{$tablet} {
        padding: 5px;
      }
      @media #{$slim} {
        font-size: 16px
      }
    }
  }
  .dynamic {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    div {
      @include font(smaller);
      span {
        @include font(large);
        white-space: nowrap;
        @media #{$tablet} {
          @include font(small);
        }
        @media #{$phone} {
          @include font(large);
        }
      }
    }
  }
}
</style>
