<template>
  <div class="catchCustomer">
    <div class="fansPlay">
      <span class="s1">折價券</span>
      <span class="s2">優惠券代碼</span>
      <span class="s3">免費贈禮</span>
      <span class="s4">試用品</span>
      <span class="s5">贈品</span>
      <img src="@/assets/image/products/fansPlay2.svg">
      <font>Fans Play 玩粉絲</font>
    </div>
    <div class="progress">
      <div class="arrowBox">
        <i class="fas fa-angle-right"></i>
        <i class="fas fa-angle-right"></i>
        <i class="fas fa-angle-right"></i>
      </div>
    </div>
    <div class="chain">
      <img class="saling a1" src="@/assets/image/products/saling.svg">
      <font class="saling a1">銷售</font>
      <img class="potential a2" src="@/assets/image/products/potential.svg">
      <font class="potential a2">潛在客戶</font>
      <img class="interact a3" src="@/assets/image/products/interact.svg">
      <font class="interact a3">互動</font>
      <span>Fans Play<br>運作鏈</span>
      <div class="circle">
        <div class="mask"></div>
        <i class="fas fa-angle-right arrow a1"></i>
        <i class="fas fa-angle-right arrow a2"></i>
        <i class="fas fa-angle-right arrow a3"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

$iconSize: 180px;
$animationTime: 5s;
$adjust: "only screen and (max-width : 1180px)";

.catchCustomer {
  $Cwidth: 75vw;
  width: $Cwidth;
  height: $Cwidth * 4/9;
  margin: $iconSize / 2 0;
  display: flex;
  transform: translateX(-5%);
  div {
    position: relative;
  }
  .fansPlay {
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      width: 60%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include font(small);
      font-weight: 700;
      margin: 3% 0;
      box-shadow: 1px 1px 10px 0px #707070;
      @for $i from 1 to 6 {
        &.s#{6 - $i} {
          animation: popitem#{$i} $animationTime;
          @keyframes popitem#{$i} {
            0% {
              transform: scale(0);
            }
            #{$i * 1% + 8} {
              transform: scale(0);
            }
            #{$i * 1% + 10} {
              transform: scale(1.2);
            }
            #{$i * 1% + 12} {
              transform: scale(1);
            }
          }
        }
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: 50% 100%;
      animation: popOut $animationTime;
      @keyframes popOut {
        0% {
          transform: scale(0);
        }
        6% {
          transform: scale(0);
        }
        10% {
          transform: scale(1.2);
        }
        15% {
          transform: scale(1);
        }
      }
    }
    font {
      @include font(medium);
      position: absolute;
      top: 100%;
    }
  }
  .progress {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .arrowBox {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      @for $i from 1 to 4 {
        :nth-child(#{$i}) {
          color: #707070;
          font-size: 160px - $i * 14px;
          @media #{$adjust} {
            font-size: 120px - $i * 14px;
            @media #{$phone} {
              font-size: 60px - $i * 8px;
            }
          }
          animation: poparrow#{$i} $animationTime;
          @keyframes poparrow#{$i} {
            0% {
              transform: scale(0);
            }
            #{$i * 4% + 12} {
              transform: scale(0);
            }
            #{$i * 4% + 14} {
              transform: scale(1.2);
            }
            #{$i * 4% + 16} {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  .chain {
    flex: 4;
    .circle {
      position: relative;
      $circleSize: 100%;
      width: $circleSize;
      height: $circleSize;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &::after,
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: $circleSize;
        height: $circleSize;
        border-radius: 100%;
        border: 2px solid;
        transform: rotate(-75deg);
      }
      &::before {
        border-top-color: #707070;
        border-right-color: #707070;
        border-bottom-color: transparent;
        border-left-color: transparent;
        z-index: 3;
        animation: rightHalf $animationTime;
        @keyframes rightHalf {
          0% {
            z-index: 0;
          }
          58% {
            z-index: 0;
          }
          59% {
            z-index: 3;
          }
        }
      }
      &::after {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: #707070;
        border-left-color: #707070;
        z-index: 1;
        animation: leftHalf $animationTime;
        @keyframes leftHalf {
          0% {
            opacity: 0;
          }
          58% {
            opacity: 0;
          }
          59% {
            opacity: 1;
          }
        }
      }
      .mask {
        position: absolute;
        border-radius: 0 100% 100% 0;
        background-color: #fff;
        top: -2.5%;
        right: -5%;
        width: 55%;
        height: 105%;
        z-index: 2;
        transform-origin: 0 50%;
        transform: rotate(-120deg);
        animation: rot $animationTime;
        @keyframes rot {
          20% {
            transform: rotate(-120deg);
          }
          50% {
            transform: rotate(0deg);
          }
          80% {
            transform: rotate(120deg);
          }
          100% {
            transform: rotate(240deg);
          }
        }
      }
      .arrow {
        $fontSize: 80px;
        font-size: $fontSize;
        height: calc(100% + #{$fontSize});
        @media #{$phone} {
          $fontSize: 40px;
          font-size: $fontSize;
          height: calc(100% + #{$fontSize});
        }
        position: absolute;
        z-index: 3;
        @for $i from 1 to 4 {
          &.a#{$i} {
            transform: rotate(($i - 1) * 120deg - 25);
            animation: aShow#{$i} $animationTime;
            @keyframes aShow#{$i} {
              0% {
                opacity: 0;
              }
              #{$i * 30 + 4%} {
                opacity: 0;
              }
              #{$i * 30 + 10%} {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    span {
      @include font(large);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
    }
    img,
    font {
      position: absolute;
      width: $iconSize;
      height: $iconSize;
      z-index: 4;
      &.saling {
        top: calc(75% - #{$iconSize} / 2);
        left: 0 - $iconSize / 2;
      }
      &.potential {
        top: 0 - $iconSize / 2;
        left: calc(50% - #{$iconSize} / 2);
      }
      &.interact {
        top: calc(75% - #{$iconSize} / 2);
        left: calc(100% - #{$iconSize} / 2);
      }
      @for $i from 1 to 4 {
        &.a#{$i} {
          animation: iconShow#{$i} $animationTime;
          @keyframes iconShow#{$i} {
            0% {
              opacity: 0;
            }
            #{($i - 1) * 30 + 14%} {
              opacity: 0;
            }
            #{($i - 1) * 30 + 20%} {
              opacity: 1;
            }
          }
        }
      }
    }
    font {
      transform: translateY($iconSize);
      @include font(smaller);
    }
  }
  @media #{$adjust} {
    flex-direction: column;
    width: 70vw;
    height: 70vw * 10/3;
    transform: translateX(0);
    margin-top: 20px;
    .fansPlay {
      flex: 5;
      justify-content: flex-start;
      span {
        margin: 1.8% 0;
      }
    }
    .progress {
      flex: 2;
      .arrowBox {
        width: 40%;
        height: 80%;
        transform: rotate(90deg);
      }
    }
    .chain {
      flex: 3;
      @mixin icons($iconSize) {
        img,
        font {
          width: $iconSize;
          height: $iconSize;
          &.saling {
            top: calc(75% - #{$iconSize} / 2);
            left: 0 - $iconSize / 2;
          }
          &.potential {
            top: 0 - $iconSize / 2;
            left: calc(50% - #{$iconSize} / 2);
          }
          &.interact {
            top: calc(75% - #{$iconSize} / 2);
            left: calc(100% - #{$iconSize} / 2);
          }
        }
        font {
          transform: translateY($iconSize);
        }
      }
      @media #{$adjust} {
        @include icons(160px);
        @media #{$phone} {
          @include icons(95px);
        }
      }
    }
  }
}
</style>
